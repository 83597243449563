module.exports = {
  content: ["./src/**/*.{html,twig,js}"],
  plugins: [
    require("daisyui"),
    require("@tailwindcss/line-clamp"),
    require("@tailwindcss/typography"),
    require("@tailwindcss/forms"),
  ],
  theme: {
    container: {
      center: true,
    },
    extend: {
      colors: {
        brown: "#1E1A16",
        dark: "#0e0e0e",
        gold: "#F8E5D2",
        darkbrown: "#131110",
      },
    },
    fontFamily: {
      body: ["Inter", "system-ui", "sans-serif"],
      display: ["Abhaya Libre", "system-ui", "sans-serif"],
    },
  },
  daisyui: {
    themes: [
      {
        mytheme: {
          primary: "#D1A270",
          secondary: "#1f2937",

          accent: "#1FB2A5",
          neutral: "#191D24",
          info: "#3ABFF8",
          success: "#36D399",
          warning: "#FBBD23",
          error: "#F87272",
        },
      },
    ],
  },
};
