import Swiper, { EffectCoverflow, Navigation } from "swiper";

if (!window.Swiper) {
  window.Swiper = Swiper;
  window.Swiper.use([EffectCoverflow, Navigation]);
}

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const tailwind = resolveConfig(tailwindConfig);

if (!window.tailwind) {
  window.tailwind = tailwind;
}

// Extend element width to the right edge of the viweport
const dataToRight = () => {
  Object.values(document.querySelectorAll("[data-to-right]")).forEach((el) => {
    el.style.width = `${window.innerWidth - el.offsetLeft}px`;
  });
};

window.addEventListener("load", () => {
  dataToRight();
});

window.addEventListener("resize", () => {
  dataToRight();
});

// document.addEventListener("click", (el) => {
//   // TABS
//   if (el.target.parentElement.hasAttribute("data-tabs")) {
//     el.preventDefault();
//     const target = document.getElementById(el.target.getAttribute("href"));

//     Object.values(el.target.parentElement.children).forEach((el) => {
//       el.classList.remove("active");
//     });
//     el.target.classList.add("active");

//     Object.values(target.parentElement.children).forEach((el) => {
//       el.classList.remove("show");
//     });
//     target.classList.add("show");
//   }
// });
